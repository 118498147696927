import React, { useState } from 'react'

import styles from './contact-form.module.scss'
import Container from '../container/container'

const ContactForm = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [company, setCompany] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')

  return (
    <Container>
      <form
        method="post"
        action="/contact-success"
        netlify-honeypot="bot-field"
        data-netlify="true"
        name="Max2 Security Contact Form"
        className={styles.form}
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="Max2 Security Contact Form" />
        <input
          type="text"
          className={styles.textInput}
          name="name"
          placeholder="Name"
          value={name}
          onChange={e => setName(e.target.value)}
          required
          autoFocus
        />
        <input
          type="email"
          className={styles.textInput}
          name="email"
          placeholder="Email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          required
        />
        <input
          type="text"
          className={styles.textInput}
          name="company"
          placeholder="Company"
          value={company}
          onChange={e => setCompany(e.target.value)}
        />
        <input
          type="text"
          className={styles.textInput}
          name="phone"
          placeholder="Phone"
          value={phone}
          onChange={e => setPhone(e.target.value)}
        />
        <textarea
          className={styles.textArea}
          name="message"
          placeholder="Tell us how we can help you..."
          value={message}
          onChange={e => setMessage(e.target.value)}
        ></textarea>
        <div className={styles.formActions}>
          <button className={styles.submitButton}>Submit</button>
        </div>
      </form>
    </Container>
  )
}
 
export default ContactForm
