import React from 'react'

import Layout from '../components/layout/layout'
import SubHero from '../components/sub-hero/sub-hero'
import ContactForm from '../components/contact-form/contact-form'

const ContactPage = () => {
  return (
    <div>
      <Layout title="Contact Us">
        <SubHero
          title="Contact Us"
          subtitle="Learn more about our cyber risk assessments, cyber
            defense investment rationale, security reporting standards, 
            or organizational governance best practices."
        />
        <div style={{ height: '5rem' }} />
        <ContactForm />
      </Layout>
    </div>
  )
}

export default ContactPage
